import spbAdmin from "./api/SpbAdminApi";
class OperatingUnitService {
    getDBInstance(payload) {
        const url = 'spb/operationunit/getdbinstancebytnname/'+payload;
        return spbAdmin.get(url);
      }
    
      getSupplierByDBINstance(payload) {
        const url = 'spb/suppliers/getsuppliersbydbInstance/'+payload;
        return spbAdmin.get(url);

      }
      
      getStatusType() {
        const url = 'spb/operationunit/getStatusType/';
        return spbAdmin.get(url);

      }
      
      getAllModuloperationunit() {
        const url = 'spb/operationunit/getallmodules'
        return spbAdmin.get(url);

      }

}
export default new OperatingUnitService();
