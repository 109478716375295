import spbKkt from "./api/SpbKktApi";
class GroupService {
    getDBInstance(payload) {
        const url = 'spb/operationunit/getdbinstancebytnname/';
        return spbKkt.get(url, payload);
      }
    
      getSupplierByDBINstance(payload) {
        const url = 'spb/suppliers/getsuppliersbydbInstance/';
        return spbKkt.get(url, payload);

      }
      
      getStatusType() {
        const url = 'spb/operationunit/getStatusType/';
        return spbKkt.get(url);

      }
      
      getAllModuloperationunit() {
        const url = 'spb/operationunit/getallmodules'
        return spbKkt.get(url);

      }

      getAllModules(id) {
        const url = `adm/accmod/${id}`;
        return spbKkt.get(url);
      }
    
      getAllMenuByModuleId(id) {
        const url = `adm/accmod/menusmodule/${id}`;
        return spbKkt.get(url);
      }
      getAllMenuByModuleIdEdit(id) {
        const url = `adm/accmod/menusmodule/${id}`;
        return spbKkt.get(url);
      }
    
      getAllMenuByModuleIdAndGroupId(id, groupid) {
        const url = `adm/accmod/menusmodule/${id}/${groupid}`;
        return spbKkt.get(url);
      }
    
      getModuleWithMenu() {
        const url = 'adm/group/getallmodulewithmenu';
        return this.service.get(url);
      }
      regisGroup(payload) {
    
    
    
    
        const url = 'adm/group/regisGroup';
        return spbKkt.post(url, payload)
      }
    
      editGroup(payload) {
        const url = 'adm/group/editGroup';
        return spbKkt.post(url, payload)
      }
    
      editGroupActive(payload) {
        const url = 'adm/group/editGroupActive';
        return spbKkt.post(url, payload)
      }

      getAllGroup() {
        const url = 'adm/group/getAllGroup';
        return spbKkt.get(url);
      }
      getAllGroupForData() {
        const url = 'adm/group/getAllGroupForData';
        return spbKkt.get(url);
      }
      getExternalGroup(){
        const url = 'adm/group/getExternalGroup';
        return spbKkt.get(url);
      }
    
      getGroupDetail(id) {
        const url = `adm/group/getDetailsGroup/${id}`;
        return spbKkt.get(url);
      }
    
      deleteGroup(payload) {
        const url = 'adm/group/deleteGroup';
        return spbKkt.post(url, payload);
      }
}
export default new GroupService();
