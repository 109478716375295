<template>
	<div class="grid">
		<Toast />
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12">
			<div class="card">
				<div class="formgrid grid">
					<div class="field col-12 md:col-6">
						<div class="field">
							<label>User Status</label>
							<SelectButton
								:disabled="disableInput"
								v-model="userlevel"
								:options="empOrSup"
								placeholder="Select"
								optionLabel="label"
								class="inputfield w-full"
							/>
						</div>
						<!-- <div v-if="userlevel.label === 'Employee' && $route.params.id === '0'" class="field">
                          <label for="dropdown">Company</label>
                          <Dropdown id="dropdown" @change="getEmpSup" v-model="selectedComp" :options="companyList" optionLabel="tnname" class="inputfield w-full" />
                      </div> -->
						<div class="p-fluid">
							<div
								v-if="
									userlevel.label === 'Employee' &&
									$route.params.id === '0'
								"
								class="field"
							>
								<label for="auto">Employee Name</label>
								<AutoComplete
									class="inputfield w-full"
									id="auto"
									v-model="empValue"
									:suggestions="empValueFiltered"
									@complete="searchEmployee($event)"
									@item-select="empSupChangeValue($event)"
									field="empValue"
									forceSelection
								>
									<template #item="{ item }">
										<div>
											<div>{{ item }}</div>
										</div>
									</template>
								</AutoComplete>
							</div>
						</div>
						<div class="field">
							<label
								>Full Name
								<small class="p-error">*</small></label
							>
							<span v-if="error.fullname" class="p-error"
								>Full Name is Required</span
							>
							<InputText
								:disabled="adminStatus"
								type="text"
								v-model="payload.fullname"
								class="inputfield w-full"
							></InputText>
						</div>
						<div class="field">
							<label
								>User Name
								<small class="p-error">*</small></label
							>
							<span v-if="error.userid" class="p-error"
								>User Name is Required</span
							>
							<InputText
								:disabled="disableInput"
								type="text"
								v-model="payload.userid"
								class="inputfield w-full"
							></InputText>
						</div>
						<div class="field">
							<label
								>Password
								<small class="p-error">*</small></label
							>
							<span v-if="error.password" class="p-error"
								>Password is Required</span
							>
							<!-- <InputText
								:disabled="adminStatus"
								type="password"
								v-model="payload.password"
								class="inputfield w-full"
							></InputText> -->
							<Password
								class="inputfield w-full"
								:disabled="adminStatus"
								toggleMask
								v-model="payload.password"
							/>
						</div>
						<div class="field">
							<label>Description</label>
							<InputText
								:disabled="adminStatus"
								type="text"
								v-model="payload.description"
								class="inputfield w-full"
							></InputText>
						</div>
					</div>
					<div class="field col-12 md:col-6">
						<div class="field">
							<label
								>Access Menu Privilege
								<small class="p-error">*</small></label
							>
							<span v-if="error.orgMlt" class="p-error"
								>Access Menu Privilege is Required</span
							>
							<!-- <AutoComplete
                              class="inputfield w-full"
                              v-model="groupValue"
                              :suggestions="groupFiltered"
                              @complete ="searchGroup($event)"
                             
                              field="groupValue"
                              :dropdown="true"
                              >
                              <template #item="{ item }">
                                  <div>
                                  <div>{{ item }}</div>
                                  </div>
                              </template>                            
                          </AutoComplete> -->
							<Dropdown
								:disabled="adminStatus"
								v-model="payload.orgMlt"
								:options="grouplist"
								placeholder="Select"
								optionLabel="name"
								class="inputfield w-full"
							/>
							<!-- <Chip v-for="val in payload.orgMlt" :key="val.id" :label="val.name" :removable="true" @remove="removeGroup(val.id)"  class="mt-2 mr-2"/> -->
						</div>
						<div class="field grid">
							<div class="field col-12 md:col-6">
								<label class="mr-4">Start Date</label>
								<Calendar
									:disabled="adminStatus"
									:showIcon="true"
									v-model="empStartdate"
								></Calendar>
							</div>
							<div class="field col-12 md:col-6">
								<label class="mr-4">End Date</label>
								<Calendar
									:disabled="adminStatus"
									:showIcon="true"
									v-model="empEndDate"
								></Calendar>
							</div>
						</div>
						<div class="field">
							<label
								>User Code
								<small class="p-error">*</small></label
							>
							<span v-if="error.usercode" class="p-error"
								>User Code is Required</span
							>
							<InputText
								:disabled="adminStatus"
								type="text"
								v-model="payload.usercode"
								class="inputfield w-full"
							></InputText>
						</div>
						<!-- <div class="field"> -->
						<!-- <label >Email Address</label>
                        <InputText type="text" v-model="payload.emailname" class="inputfield w-full"></InputText>               
                      </div>
                      <div class="field">
                        <label>CC Address 1</label>
                        <InputText type="text" v-model="payload.email1" class="inputfield w-full"></InputText>               
                      </div>
                      <div class="field">
                        <label >CC Address 2</label>
                        <InputText type="text" v-model="payload.email2" class="inputfield w-full"></InputText>               
                      </div> -->
						<div class="p-fluid">
							<div class="field">
								<label
									>Email Address
									<small class="p-error">*</small></label
								>
								<span v-if="error.emailname" class="p-error"
									>Email Address is Required</span
								>
								<Chips
									:disabled="adminStatus"
									v-model="payload.emailname"
									class="inputfield w-full"
									separator=","
								/>
								<small
									>*) Press enter or comma to submit
									email</small
								>
							</div>
						</div>
						<div class="field">
							<label>Active</label>
							<Dropdown
								:disabled="adminStatus"
								v-model="payload.isactive"
								:options="activeOptionValues"
								optionLabel="label"
								placeholder="Select"
								class="inputfield w-full"
							/>
						</div>
					</div>
				</div>
			</div>
			<div v-if="userlevel.label === 'Employee'" class="card">
				<div>
					<div class="card">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3 lg:mb-0">
								<span class="p-float-label">
									<!-- <Dropdown
										id="dropdown"
										@change="getEmpSup"
										v-model="selectedComp"
										:options="companyList"
										optionLabel="tnname"
										class="inputfield w-full"
									/> -->
									<AutoComplete
										id="dropdown"
										class="w-full"
										:suggestions="filteredComp"
										:dropdown="true"
										field="tnname"
										forceSelection
										v-model="selectedComp"
										@complete="searchComp($event)"
										@item-select="getEmpSup()"
									/>
									<label for="dropdown">Company</label>
								</span>
							</div>
							<div class="col-12 mb-2 lg:col-2 lg:mb-0">
								<span class="p-float-label">
									<AutoComplete
										class="w-full col"
										id="auto"
										v-model="supValue"
										:suggestions="supValueFiltered"
										@complete="searchSupplier($event)"
										:dropdown="true"
										field=""
										forceSelection
									>
										<template #item="slotProps">
											<div class="country-item pl-2">
												<div class="mr-2">
													{{ slotProps.item }}
												</div>
											</div>
										</template>
									</AutoComplete>
									<label for="auto">Supplier Name</label>
								</span>
							</div>
							<div
								v-if="userlevel.label === 'Employee'"
								class="col-12 ml-6 mb-5 lg:col-1 lg:mb-0"
							>
								<Button
									label="Add"
									@click="addsuppliertables"
								/>
							</div>
						</div>
					</div>

					<DataTable
						v-if="userlevel.label === 'Employee'"
						:value="supplierselectlist"
						dataKey="id"
						:paginator="true"
						:rows="25"
						v-model:filters="filters"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[25, 50, 75]"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:globalFilterFields="[
							'company',
							'fullname',
							'userid',
							'tenantname',
							'targetemailgroup',
							'bioemailactive',
						]"
					>
						<template #header>
							<div class="flex justify-content-between">
								<Button
									type="button"
									icon="pi pi-filter-slash"
									label="Clear"
									class="p-button-outlined"
									@click="clearFilter1()"
								/>
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText
										v-model="filters['global'].value"
										placeholder="Keyword Search"
									/>
								</span>
							</div>
						</template>
						<Column
							class="call-button-container"
							header="Company"
							field="company"
						></Column>
						<Column
							class="call-button-container"
							header="Supplier"
							field="fullname"
						></Column>
						<Column header="Email">
							<template #body="slotProps">
								<Chips
									v-model="slotProps.data.targetemailgroup"
									class="inputfield w-full"
								/>
							</template>
						</Column>
						<Column header="Start Date">
							<template #body="slotProps">
								<Calendar
									v-model="slotProps.data.startdate"
									class="inputfield w-full"
								/>
							</template>
						</Column>
						<Column header="End Date">
							<template #body="slotProps">
								<Calendar
									v-model="slotProps.data.enddate"
									class="inputfield w-full"
								/>
							</template>
						</Column>
						<Column header="Active">
							<template #body="slotProps">
								<div v-if="slotProps.data.active === 1">
									<Button
										icon="pi pi-check"
										class="p-button-rounded p-button-outlined"
										@click="deactiveSupp(slotProps.index)"
									/>
								</div>
								<div v-else>
									<Button
										icon="pi pi-times"
										class="p-button-rounded p-button-danger p-button-outlined"
										@click="activeSupp(slotProps.index)"
									/>
								</div>
								<!-- <Dropdown  v-model="slotProps.data.active"  :options="activeOptionValues" placeholder="Select" optionLabel="label" class="inputfield w-full" /> -->
							</template>
						</Column>
						<Column
							header="Action"
							:style="{ width: '150px' }"
							v-if="$route.params.id === '0'"
						>
							<template #body="slotProps">
								<Button
									icon="pi pi-trash"
									class="p-button-rounded p-button-danger mt-2"
									@click="delsuppliertables(slotProps)"
								/>
							</template>
						</Column>
						<template #empty> No data found. </template>
					</DataTable>
				</div>
			</div>
			<div v-if="userlevel.label === 'Supplier'" class="card">
				<div>
					<div class="card">
						<div class="grid formgrid" style="margin: 1em 0 0 0">
							<div class="col-12 mb-5 lg:col-3 lg:mb-0">
								<span class="p-float-label">
									<!-- <Dropdown
										id="dropdown"
										@change="getEmpSup"
										v-model="selectedComp"
										:options="companyList"
										optionLabel="tnname"
										class="inputfield w-full"
									/> -->
									<AutoComplete
										id="dropdown"
										class="w-full"
										:suggestions="filteredComp"
										:dropdown="true"
										field="tnname"
										forceSelection
										v-model="selectedComp"
										@complete="searchComp($event)"
										@item-select="getEmpSup()"
									/>
									<label for="dropdown">Company</label>
								</span>
							</div>
							<div class="pl-2">
								<span class="p-float-label">
									<AutoComplete
										class="w-full col"
										id="auto"
										v-model="supValue"
										:suggestions="supValueFiltered"
										@complete="searchSupplier($event)"
										:dropdown="true"
										field=""
										forceSelection
									>
										<template #item="slotProps">
											<div class="country-item pl-2">
												<div class="mr-2">
													{{ slotProps.item }}
												</div>
											</div>
										</template>
									</AutoComplete>
									<label for="auto">Supplier Name</label>
								</span>
							</div>
							<div class="pl-2">
								<span class="p-float-label">
									<AutoComplete
										class="inputfield w-full"
										id="auto"
										v-model="empValue"
										:suggestions="empValueFiltered"
										@complete="searchEmployee($event)"
										@item-select="empSupChangeValue($event)"
										:dropdown="true"
										field="empValue"
										forceSelection
									>
										<template #item="{ item }">
											<div>
												<div>{{ item }}</div>
											</div>
										</template>
									</AutoComplete>
									<label for="auto">Employee Name</label>
								</span>
							</div>
							<!-- <span class="p-float-label">
                                <AutoComplete v-model="selectedSupplier" :suggestions="filteredSupplier" @complete="searchSupp($event)" 
                                :dropdown=true field="vendor_name" class="inputfield w-full" forceSelection>
                                    <template #item="slotProps">
                                        <div class="country-item pl-2" >
                                            <div class="mr-2">{{slotProps.item.vendor_name}}</div>
                                        </div>
                                    </template>
                                </AutoComplete>
                                <label for="auto">Supplier Name</label>
                            </span> -->
							<div
								v-if="userlevel.label === 'Supplier'"
								class="pl-2"
							>
								<Button
									label="Add"
									@click="addsuppliertables"
								/>
							</div>
						</div>
					</div>

					<DataTable
						v-if="userlevel.label === 'Supplier'"
						:value="supplierselectlist"
						dataKey="id"
						:paginator="true"
						:rows="25"
						v-model:filters="filters"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[25, 50, 75]"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:globalFilterFields="[
							'company',
							'fullname',
							'employeename',
							'targetemailgroup',
							'userid',
							'tenantname',
							'bioemailactive',
						]"
					>
						<template #header>
							<div class="flex justify-content-between">
								<Button
									type="button"
									icon="pi pi-filter-slash"
									label="Clear"
									class="p-button-outlined"
									@click="clearFilter1()"
								/>
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText
										v-model="filters['global'].value"
										placeholder="Keyword Search"
									/>
								</span>
							</div>
						</template>
						<Column
							class="call-button-container"
							header="Company"
							field="company"
						></Column>
						<Column
							class="call-button-container"
							header="Supplier"
							field="fullname"
						></Column>
						<Column
							class="call-button-container"
							header="Employee Name"
							field="employeename"
						></Column>

						<Column header="Email">
							<template #body="slotProps">
								<Chips
									v-model="slotProps.data.targetemailgroup"
									class="inputfield w-full"
								/>
							</template>
						</Column>
						<Column header="Active">
							<template #body="slotProps">
								<div v-if="slotProps.data.active === 1">
									<Button
										icon="pi pi-check"
										class="p-button-rounded p-button-outlined"
										@click="deactiveSupp(slotProps.index)"
									/>
								</div>
								<div v-else>
									<Button
										icon="pi pi-times"
										class="p-button-rounded p-button-danger p-button-outlined"
										@click="activeSupp(slotProps.index)"
									/>
								</div>
								<!-- <Dropdown  v-model="slotProps.data.active"  :options="activeOptionValues" placeholder="Select" optionLabel="label" class="inputfield w-full" /> -->
							</template>
						</Column>
						<Column
							header="Action"
							:style="{ width: '150px' }"
							v-if="$route.params.id === '0'"
						>
							<template #body="slotProps">
								<Button
									icon="pi pi-trash"
									class="p-button-rounded p-button-danger mt-2"
									@click="delsuppliertables(slotProps)"
								/>
							</template>
						</Column>
						<template #empty> No data found. </template>
					</DataTable>
				</div>
			</div>
			<div class="card">
				<div>
					<Button
						label="Back"
						@click="goback"
						class="p-button-secondary mr-2 mb-2 my-auto ml-2"
					/>
					<Button
						label="Save"
						@click="onSubmit"
						class="p-button-primary mr-2 mb-2 my-auto"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import umService from '../../service/UserManagerService';
import groupService from '../../service/GroupService';
import operatingunitService from '../../service/OperatingUnitService';
import authservice from '../../service/AuthService';
import LovService from '../../service/LovService';
import { FilterMatchMode } from 'primevue/api';

export default {
	data() {
		return {
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Admin', to: '/dashboard' },
				{ label: 'Users', to: '/mgm/user/userslist' },
			],
			column: [
				{ header: 'Company', field: 'company', width: '200px' },
				{ header: 'Supplier Name', field: 'fullname', width: '150px' },
			],
			payload: {
				fullname: null,
				usercode: null,
				userid: null,
				password: 'qwerty123',
				group: [],
				isactive: { label: 'YES', value: 1 },
				emailname: null,
				description: null,
				startdate: new Date(),
				// enddate: new Date(new Date().setDate(new Date().getDate() + 365)),
				enddate: null,
				emplobj: [{}],
				suplobj: [[]],
				orgMlt: [],
				userlevel: { label: 'Employee', value: 3 },
				temppass: 'qwerty123',
				tenantchoosed: [{}],
				emailusr: [],
				emailname1: '',
				emailname2: '',
			},
			adminStatus: false,
			opUnitValue: null,
			opUnitOptionValues: ['DXM DOMESTIC', 'DXM OBU'],
			activeValue: null,
			activeOptionValues: [
				{ label: 'YES', value: 1 },
				{ label: 'NO', value: 0 },
			],
			startDate: null,
			endDate: null,
			filters: null,
			empsupStatusOpt: [
				{ label: 'Employee', value: 3 },
				{ label: 'Supplier', value: 2 },
			],
			empStartdate: this.formatDate(new Date())[0],
			empEndDate: null,
			empsupStatus: {},

			extraInfo: null,
			isEdit: null,
			userId: null,
			stateOptions: null,
			stateOptionsEdit: null,

			empOrSup: null,
			empsupClicked: null,
			empList: null,
			empSuggest: null,
			selectedComp: null,
			companyList: null,
			filteredComp: null,
			supList: null,
			supSuggest: null,

			supplierselectlist: [],
			selectedSpl: null,
			breadcrumbs: null,
			home: null,
			leveltenant: null,
			userInfo: null,
			selectedApps: null,
			tokenID: null,
			groupForm: null,
			submitted: null,
			orgsData: null,
			appInfoActive: null,
			orgSuggest: null,
			formatedOrg: null,
			optunit: null,

			//for employee username suggesetion
			empValue: null,
			empIdValue: null,
			empValueFiltered: [],
			empValues: [],
			emailAddress: null,

			//for Supplier username suggesetion
			supValue: null,
			supValueFiltered: [],
			supValues: [],

			//for group suggestion
			groupValue: null,
			groupFiltered: [],
			grouplist: [],
			selectedGroup: null,

			supplierEmail: [],
			targetEmailSpl: [],
			emailChip: [],

			disableInput: false,
			activeEmailValue: [],

			empCompList: [],
			selectedEmpComp: null,
			filteredSupplier: null,
			selectedSupplier: null,
			supplierList: [],
			userlevel: { label: 'Employee', value: 3 },
			isEditColomn: false,
			error: {
				fullname: false,
				usercode: false,
				userid: false,
				password: false,
				emailname: false,
				startdate: false,
				orgMlt: false,
				empValue: false,
			},
			errors: [],
		};
	},
	computed: {},
	created() {
		this.initFilters();
	},
	watch: {
		empStartdate(newValue) {
			if (newValue instanceof Date) {
				this.empStartdate = this.formatDate(newValue)[0];
			}
		},
		empEndDate(newValue) {
			if (newValue instanceof Date) {
				this.empEndDate = this.formatDate(newValue)[0];
			}
		},
		supValue(input) {
			this.getSelectedSpl(input);
		},
		selectedEmpComp(event) {
			this.getSuppName(event);
		},
		// empValue(input){

		//   this.empSupChangeValue(input)

		// },
		// groupValue(input){

		//   this.getSelectedGroup(input)

		// }
		// supplierselectlist:{
		//   handler(newValue){
		//
		//   }
		// },
		supplierselectlist: {
			handler(newValue) {
				const entries = Object.entries(newValue);

				entries.forEach((element) => {
					if (element['1']['startdate'] instanceof Date) {
						element['1']['startdate'] = this.formatDate(
							element['1']['startdate']
						)[0];
					}

					if (element['1']['enddate'] instanceof Date) {
						element['1']['enddate'] = this.formatDate(
							element['1']['enddate']
						)[0];
					}
					// if(!this.pushStatus){
					//     var dateParts = element["1"]["need_by_date"].split("/");
					//     // month is 0-based, that's why we need dataParts[1] - 1
					//     var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

					//     element["1"]["need_by_date"] = dateObject.toString();
					// }
				});
			},
			deep: true,
		},
		userlevel() {
			if (!this.isEdit) {
				this.supValue = null;
				this.empValue = null;
				this.empValues = null;
				this.supplierselectlist = [];
				this.empList = [];
				this.payload.emailname = [];
				this.payload.fullname = null;
				this.payload.usercode = null;
				this.payload.startdate = new Date();
				this.payload.enddate = null;
				this.payload.description = null;
				this.selectedComp = null;
			}
		},
		selectedComp() {
			this.supValue = null;
			this.empValue = null;
		},
	},

	methods: {
		pad2(n) {
			return (n < 10 ? '0' : '') + n;
		},
		formatDate(date) {
			if (date instanceof Date) {
				var month = this.pad2(date.getMonth() + 1); //months (0-11)
				var day = this.pad2(date.getDate()); //day (1-31)
				var year = date.getFullYear();
				var combinedNeedDate = new Date(month + '/' + day + '/' + year);
				let formattedDate = [];
				if (
					date ==
					'Thu Jan 01 1970 07:00:00 GMT+0700 (Western Indonesia Time)'
				) {
					formattedDate[0] = '';
					formattedDate[1] = '';
				} else {
					formattedDate[0] =
						combinedNeedDate.getDate() +
						'-' +
						combinedNeedDate.toString().substr(4, 3) +
						'-' +
						combinedNeedDate.getFullYear();
					formattedDate[1] =
						combinedNeedDate.getMonth() +
						1 +
						'/' +
						combinedNeedDate.getDate() +
						'/' +
						combinedNeedDate.getFullYear();
				}

				return formattedDate;
			}
		},
		stringToDate(date) {
			let needDate = date.toString;

			let needDateProcessA = needDate.substring(0, 2);
			let needDateProcessB = needDate.substring(3, 5);
			let needDateProcessC = needDate.substring(6, 10);
			let combinedNeedDate =
				needDateProcessB +
				'/' +
				needDateProcessA +
				'/' +
				needDateProcessC;
			let convertdate = new Date(combinedNeedDate.substring(0, 10));
			this.dateConvert =
				convertdate.getDate() +
				'-' +
				convertdate.toString().substr(4, 3) +
				'-' +
				convertdate.getFullYear();
			return this.dateConvert.toUpperCase();
		},
		getSuppName(event) {
			let payload = {
				db_instance: event.db_instance,
				org_id: event.value,
			};
			LovService.getSupplierName(payload).then((orgall) => {
				this.supplierList = orgall.data.data;
			});
		},
		searchComp(event) {
			if (!event.query.trim().length) {
				this.filteredComp = [...this.companyList];
			} else {
				this.filteredComp = this.companyList.filter((comp) => {
					return comp.tnname
						.toLowerCase()
						.match(event.query.toLowerCase());
				});
			}
		},
		searchSupp(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredSupplier = [...this.supplierList];
				} else {
					this.filteredSupplier = this.supplierList.filter(
						(supplier) => {
							if (supplier.vendor_name !== null) {
								return supplier.vendor_name
									.toLowerCase()
									.match(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		deactiveSupp(index) {
			this.supplierselectlist[index].active = 0;
		},
		activeSupp(index) {
			this.supplierselectlist[index].active = 1;
		},
		changeActive() {},
		addSupplierEmail(data) {
			this.supplierselectlist[data.index].email.push(
				this.supplierEmail[data.index]
			);
			this.supplierEmail[data.index] = null;
		},
		removeEmailSpl(email, slotProps) {
			//
			const indexEmail = slotProps.data.targetemail1.findIndex(
				(eml) => eml === email
			);
			console.log('email', indexEmail);
			//
		},
		// for multiple group
		// change(){
		//   this.payload.orgMlt.push(this.groupValue);
		//

		// },
		delsuppliertables(payload) {
			// this.supplierselectlist.re
			this.supplierselectlist.forEach((element, index) => {
				if (index == payload.index)
					this.supplierselectlist.splice(index, 1);
			});
			// selectspl

			// let obj = {company:this.selectedComp, fullname:this.selectedSpl.label, idvendor:this.selectedSpl.code, status: 1 };
			// this.supplierselectlist.push(obj);
		},
		getSelectedSpl(a) {
			//SELECTED SUPPLIER LIST VALUE
			this.selectedSpl = this.supList.find(
				(value) => value.VENDOR_NAME === a
			);
		},
		addsuppliertables() {
			// if(this.userlevel.label === 'Supplier') {

			if (this.selectedComp == null) {
				alert('Please choose company first');
				return;
			}
			if (this.selectedSpl == null) {
				alert('Please choose Supplier');
				return;
			}
			// if(this.empValue == null){
			//   alert('Please choose Employee');
			//   return;
			// }

			// Validate for unique company and supplier
			if (
				this.supplierselectlist.some(
					(item) =>
						item.company === this.selectedComp.tnname &&
						item.fullname === this.selectedSpl.VENDOR_NAME
				)
			) {
				console.log('This company and supplier have already added');
				this.$toast.add({
					severity: 'error',
					summary: 'This company and supplier have already added',
					life: 3000,
				});
				return;
			}

			let obj;
			if (this.userlevel.label === 'Supplier') {
				obj = {
					company: this.selectedComp.tnname,
					fullname: this.selectedSpl.VENDOR_NAME,
					vendorid: this.selectedSpl.VENDOR_ID,
					active: 1,
					party_number: this.selectedSpl.PARTY_NUMBER,
					segment1: this.selectedSpl.SEGMENT1,
					targetemailgroup: [],
					employeename: this.empValue,
					employeeid: this.empList.find(
						(value) => value?.label == this.empValue
					)?.code,
				};
			} else if (this.userlevel.label === 'Employee') {
				obj = {
					company: this.selectedComp.tnname,
					fullname: this.selectedSpl.VENDOR_NAME,
					vendorid: this.selectedSpl.VENDOR_ID,
					active: 1,
					party_number: this.selectedSpl.PARTY_NUMBER,
					segment1: this.selectedSpl.SEGMENT1,
					targetemailgroup: [],
					startdate: new Date(),
					enddate: null,
				};
			}
			// this.supplierselectlist.push(obj);
			this.supplierselectlist = [obj, ...this.supplierselectlist];
			this.tableList = { ...this.supplierselectlist };

			this.supValue = null;
			this.empValue = null;
			this.selectedComp = null;
			// }
		},

		searchEmployee({ query }) {
			if (!query.trim()) {
				this.empValueFiltered = [...this.empValues];
				return;
			}
			this.empValueFiltered = this.empValues.filter((f) =>
				f.toLowerCase().includes(query.toLowerCase())
			);
		},
		searchSupplier(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.supValueFiltered = [...this.supValues];
				} else {
					this.supValueFiltered = this.supValues.filter(
						(supplier) => {
							if (supplier !== null) {
								return supplier
									.toLowerCase()
									.includes(event.query.toLowerCase());
							}
						}
					);
				}
			}, 250);
		},
		getSelectedGroup(a) {
			//SELECTED SUPPLIER LIST VALUE
			this.selectedGroup = this.grouplist.find((val) => {
				return val.name === a;
			});
			this.payload.orgMlt.push(this.selectedGroup);
		},
		searchGroup({ query }) {
			let groupnames = this.grouplist.map((val) => val.name);
			if (!query.trim()) {
				this.groupFiltered = [...groupnames];
				return;
			}
			this.groupFiltered = this.groupnames.filter((f) =>
				f.toLowerCase().includes(query.toLowerCase())
			);
		},
		empSupChangeValue(data) {
			if (this.userlevel.label === 'Employee') {
				this.payload.emailname = [];
				var email = new String(
					this.empList.find(
						(value) => value?.label == data.value
					)?.email
				);
				this.payload.fullname = this.empList.find(
					(value) => value?.label == data.value
				)?.label;
				// this.payload.usercode = this.empList.find(value => value?.label == data.value)?.code
				if (email != null) {
					this.payload.emailname.push(email);
				}
			}
		},

		clearFilter1() {
			this.initFilters();
		},

		initFilters() {
			this.filters = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},

		goback() {
			this.$router.go(-1);
		},
		save() {},

		//for toast message
		showSuccess() {
			this.$toast.add({
				severity: 'success',
				summary: 'User Created',
				life: 3000,
			});
		},
		showError() {
			this.$toast.add({
				severity: 'error',
				summary: 'Error Message',
				detail: 'Message Detail',
				life: 3000,
			});
		},
		getEmpCompSup() {
			if (this.userlevel.label === 'Supplier') {
				operatingunitService
					.getDBInstance(this.selectedEmpComp.name)
					.then((dbresult) => {
						if (dbresult.status === 200) {
							let dbinstance = dbresult.data.data.DB_INSTANCE;

							operatingunitService
								.getSupplierByDBINstance(dbinstance)
								.then((supResults) => {
									//
									this.supList = [];
									this.supList = supResults.data.data;
									this.supValues = this.supList?.map(
										(value) => value.VENDOR_NAME
									);
								});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},

		//FETCHING LIST OF SUPPLIER OR EMPLOYEE
		getEmpSup() {
			operatingunitService
				.getDBInstance(this.selectedComp.tnname)
				.then((dbresult) => {
					if (dbresult.status === 200) {
						let dbinstance = dbresult.data.data.DB_INSTANCE;

						operatingunitService
							.getSupplierByDBINstance(dbinstance)
							.then((supResults) => {
								//
								if (supResults.status === 200) {
									this.supList = [];
									this.supList = supResults.data.data;
									this.supValues = this.supList?.map(
										(value) => value.VENDOR_NAME
									);

									if (this.userlevel.label === 'Supplier') {
										umService
											.retriveEmployeeByCompanyName(
												this.selectedComp.tnname
											)
											.then((employeeResult) => {
												if (
													employeeResult.status ===
													200
												) {
													this.empList = [];
													let dataEmployees =
														employeeResult.data
															.data;
													dataEmployees.map((val) => {
														//
														let objEmpl = {
															code: val.idemployee,
															label: val.employeename,
															email: val.email1,
															// email2:val.email2,
															dbInstance:
																val.db_instance,
														};
														this.empList.push(
															objEmpl
														);

														//for suggest employee name
														this.empValues =
															this.empList.map(
																(value) =>
																	value.label
															);
													});
												} else {
													this.empList = [];
												}
											});
									}
								}
							});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},

		onSubmit() {
			this.errors = [];
			let payload = this.payload;

			if (payload.fullname === '' || payload.fullname === null) {
				this.error.fullname = true;
				this.errors.push('Fullname Is Required');
			} else {
				this.error.fullname = false;
			}
			if (payload.userid === '' || payload.userid === null) {
				this.error.userid = true;
				this.errors.push('userid Is Required');
			} else {
				this.error.userid = false;
			}
			if (payload.password === '' || payload.password === null) {
				if (this.$route.params.id === '0') {
					this.error.password = true;
					this.errors.push('password Is Required');
				} else {
					this.error.password = false;
				}
			} else {
				this.error.password = false;
			}
			if (
				payload.orgMlt === '' ||
				payload.orgMlt === null ||
				payload.orgMlt.length === 0
			) {
				this.error.orgMlt = true;
				this.errors.push('orgMlt Is Required');
			} else {
				this.error.orgMlt = false;
			}
			if (payload.startdate === '' || payload.startdate === null) {
				this.error.startdate = true;
				this.errors.push('startdate Is Required');
			} else {
				this.error.startdate = false;
			}
			if (payload.usercode === '' || payload.usercode === null) {
				this.error.usercode = true;
				this.errors.push('usercode Is Required');
			} else {
				this.error.usercode = false;
			}

			if (
				payload.emailname === '' ||
				payload.emailname === null ||
				payload.emailname.length === 0
			) {
				this.error.emailname = true;
				this.errors.push('emailname Is Required');
			} else {
				this.error.emailname = false;
			}

			// Check email fieldif empty in supplier list table
			if (
				this.supplierselectlist.find(
					(supp) =>
						supp.targetemailgroup && !supp.targetemailgroup.length
				)
			) {
				console.log('Target email group for employee ie empty');
				alert('Target email group for employee ie empty');
				return;
			}

			if (this.errors.length > 0) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				alert('Any Required Form is Empty');
			} else {
				let payloadEmployee;
				let payloadSupplier;
				if (this.isEdit) {
					payloadEmployee = {
						...this.payload,
						suplobj: this.supplierselectlist,
						startdate: this.formatDate(
							new Date(this.empStartdate)
						)[1],
						enddate: this.formatDate(new Date(this.empEndDate))[1],
						isactive: this.payload.isactive.value,
						group: [this.payload.orgMlt],
					};
					delete payloadEmployee['emailusr'];
					payloadSupplier = {
						...this.payload,
						group: [this.payload.orgMlt],
						orgMlt: [this.payload.orgMlt],
						suplobj: this.supplierselectlist,
						isactive: this.payload.isactive.value,
						userlevel: this.userlevel.value,
						tenantchoosed: this.payload.tenantchoosed,
						startdate: this.formatDate(
							new Date(this.empStartdate)
						)[1],
						enddate: this.formatDate(new Date(this.empEndDate))[1],

						// employeename: this.empValue,
						// employeeid: this.empList.find(value => value?.label == this.empValue)?.code
					};
				} else {
					if (this.userlevel.label === 'Employee') {
						if (this.empValue === '' || this.empValue === null) {
							this.error.empValue = true;
							this.errors.push('Employee Name Is Required');
						} else {
							this.error.empValue = false;
						}
					}
					this.payload.tenantchoosed = this.selectedComp;

					payloadEmployee = {
						...this.payload,
						suplobj: this.supplierselectlist,
						startdate: this.formatDate(
							new Date(this.empStartdate)
						)[1],
						enddate: this.formatDate(new Date(this.empEndDate))[1],
						isactive: this.payload.isactive.value,
					};
					delete payloadEmployee['emailusr'];
					payloadSupplier = {
						...this.payload,
						group: [this.payload.orgMlt],
						orgMlt: [this.payload.orgMlt],
						tenantchoosed:
							this.userlevel.label === 'Employee'
								? this.selectedComp
								: { id: 0, label: 'Supplier' },
						suplobj: this.supplierselectlist,
						isactive: this.payload.isactive.value,
						userlevel: this.userlevel.value,
						startdate: this.formatDate(
							new Date(this.empStartdate)
						)[1],
						enddate: this.formatDate(new Date(this.empEndDate))[1],
						// employeename: this.empValue,
						// employeeid: this.empList.find(value => value?.label == this.empValue)?.code
					};
				}
				if (this.$route.params.id !== '0') {
					//SUBMIT FOR EDIT PAGE
					const finalPayload =
						this.userlevel.label === 'Employee'
							? { ...payloadEmployee, id: this.$route.params.id }
							: { ...payloadSupplier, id: this.$route.params.id };

					delete payloadEmployee['emailusr'];
					umService.updatebyAdmin(finalPayload).then(
						(result) => {
							if (result.status === 200) {
								// this.showSuccess()
								this.$toast.add({
									severity: 'success',
									summary: 'User Updated',
									life: 3000,
								});
								setTimeout(async () => {
									await this.$router.go(-1);
								}, 3000);
							} else {
								this.$toast.add({
									severity: 'error',
									summary: 'Error Updated User',
									life: 3000,
								});
							}
						},
						(err) => {
							console.log(err);
						}
					);
					// alert('Under construction');
				} else {
					umService.isuserexist(this.payload.userid).then((resp) => {
						if (resp.status === 202) {
							//SUBMIT FOR INSERT NEW USER

							umService
								.insertByAdmin(
									this.empsupStatus.label === 'Employee'
										? payloadEmployee
										: payloadSupplier
								)
								.then(
									(result) => {
										if (result.status === 200) {
											this.showSuccess();
											setTimeout(async () => {
												await this.$router.go(-1);
											}, 3000);
										} else {
											this.$toast.add({
												severity: 'error',
												summary: 'Error Insert User',
												life: 3000,
											});
										}
									},
									(err) => {
										console.log(err);
									}
								);
						} else {
							alert('USER ALREADY EXIST');
							this.$toast.add({
								severity: 'error',
								summary: 'Error Message',
								detail: 'User Already Exist',
								life: 3000,
							});
						}
					});
				}
			}
		},
	},
	mounted() {
		this.empList = [];
		umService.retriveallemployee().then((employeeResult) => {
			if (employeeResult.data.status === 200) {
				let dataEmployees = employeeResult.data.data;
				dataEmployees.map((val) => {
					//
					let objEmpl = {
						code: val.idemployee,
						label: val.employeename,
						email: val.email1,
						// email2:val.email2,
						// dbInstance:val.db_instance
					};
					this.empList.push(objEmpl);

					//for suggest employee name
					this.empValues = this.empList.map((value) => value.label);
				});
			}
		});
		groupService.getAllGroupForData().then((grpall) => {
			this.orgsData = grpall.data.data.userGroup;
			// this.formatOrgData(this.orgsData);
			this.grouplist = this.orgsData.map((value) => {
				return { name: value.groupname, id: value.id };
			});

			//FOR EDIT PAGE
			if (this.$route.params.id !== '0') {
				this.disableInput = true;
				this.isEdit = true;
				this.userId = this.$route.params.id;
				this.isEditColomn = true;

				umService.retriveUsersById(this.userId).then(async (result) => {
					if (result.data.data.leveltenant < 2) {
						umService
							.retriveCompaniesByiduser(this.userId)
							.then((compRes) => {
								//

								if (compRes.status === 200) {
									let datacom = compRes.data.data.data;
									// datacom.emailname = JSON.parse(result.data.data.bioemailactivegroup);
									datacom.forEach((element) => {
										element['targetemailgroup'] =
											JSON.parse(
												element['targetemailgroup']
											);
										element['startdate'] = this.formatDate(
											new Date(element['startdate'])
										)[0];
										element['enddate'] =
											element['enddate'] &&
											this.formatDate(
												new Date(element['enddate'])
											)[0];
										// if(!this.pushStatus){
										//     element["1"]["need_by_date"] = new Date(element["1"]["need_by_date"]);
										// }
									});

									this.supplierselectlist = datacom;
								}
							});

						this.payload.fullname = result.data.data.fullname;
						this.payload.username = result.data.data.userid;
						this.payload.usercode = result.data.data.usercode;
						this.payload.userid = result.data.data.userid;
						this.payload.password = '';
						this.payload.description = result.data.data.description;
						this.payload.orgMlt = this.grouplist.find(
							(idgroup) =>
								result.data.data.idgroupuseracl === idgroup.id
						);
						this.payload.group = [this.payload.orgMlt];
						this.payload.isactive = this.activeOptionValues.find(
							(val) => val.value === result.data.data.active
						);
						this.payload.emailname = JSON.parse(
							result.data.data.bioemailactivegroup
						);
						(this.payload.emplobj = null),
							(this.userlevel = this.empOrSup.find(
								(val) =>
									val.value === result.data.data.leveltenant
							));
						this.empStartdate = this.formatDate(
							new Date(result.data.data.startdate)
						)[0];
						this.empEndDate =
							result.data.data.enddate &&
							this.formatDate(
								new Date(result.data.data.enddate)
							)[0];
						if (isNaN(parseFloat(this.empEndDate))) {
							this.empEndDate = null;
						}

						// this.payload.tenantchoosed.id = result.data.data.idtenant;

						let compObj = await this.companyList.filter(
							(company) => company.id === result.data.idtenant
						);
						console.log(compObj);
						// if(compObj.length > 0 ){
						// this.empList=[];
						// this.empList= await this.optUnitClickedEdit({value:compObj[0]},this.umService,);
						// let empObj = await this.empList.filter( employee => employee.label === this.user.fullname);
						// this.payload.emplobj= empObj[0],
						this.userlevel = this.empOrSup.find(
							(val) => val.value === result.data.data.leveltenant
						);

						umService
							.retriveCompaniesByiduser(this.userId)
							.then((compRes) => {
								//

								let datacom = compRes.data.data.data;
								console.log(datacom);
								// this.supplierselectlist = datacom;
								// await datacom.emailusrmap.map(async (data) => {
								//
								//   this.payload.emailusr.push(data.email)

								// })
							});

						// }
					} else {
						//supplier edit

						const compRes =
							await umService.retriveCompaniesByiduser(
								this.userId
							);

						if (compRes.status === 200) {
							let datacom = compRes.data.data.data;
							// datacom.emailname = JSON.parse(result.data.data.bioemailactivegroup);
							datacom.forEach((element) => {
								element['targetemailgroup'] = JSON.parse(
									element['targetemailgroup']
								);
								element['startdate'] = this.formatDate(
									new Date(element['startdate'])
								)[0];
								element['enddate'] =
									element['enddate'] &&
									this.formatDate(
										new Date(element['enddate'])
									)[0];
								// if(!this.pushStatus){
								//     element["1"]["need_by_date"] = new Date(element["1"]["need_by_date"]);
								// }
							});

							this.supplierselectlist = datacom;
						}

						(this.userlevel = this.empOrSup.find(
							(val) => val.value === result.data.data.leveltenant
						)),
							(this.payload.fullname = result.data.data.fullname);
						this.payload.username = result.data.data.userid;
						this.payload.usercode = result.data.data.usercode;
						this.payload.userid = result.data.data.userid;
						this.payload.password = '';
						this.payload.description = result.data.data.description;
						this.payload.orgMlt = this.grouplist.find(
							(idgroup) =>
								result.data.data.idgroupuseracl === idgroup.id
						);
						this.payload.isactive = this.activeOptionValues.find(
							(val) => val.value === result.data.data.active
						);
						this.payload.emailname = JSON.parse(
							result.data.data.bioemailactivegroup
						);
						this.payload.emplobj = null;
						this.empStartdate = this.formatDate(
							new Date(result.data.data.startdate)
						)[0];
						this.empEndDate =
							result.data.data.enddate &&
							this.formatDate(
								new Date(result.data.data.enddate)
							)[0];
						if (isNaN(parseFloat(this.empEndDate))) {
							this.empEndDate = null;
						}
						// this.payload.tenantchoosed = compObj[0]
					}
				});
			}

			this.companyList = [];
			this.stateOptions = [
				{ label: 'Active', value: 1 },
				{ label: 'Deactive', value: 0 },
			];
			this.stateOptionsEdit = [
				{ label: 'Active', value: 1 },
				{ label: 'Deactive', value: 0 },
			];
			this.empOrSup = [
				{ label: 'Employee', value: 3 },
				{ label: 'Supplier', value: 2 },
			];

			// {code:"3123123", label:"NANANG SUPARLAN", email:"nanang@gmail.com"},
			// {code:"3123124", label:"HETTY KOES", email:"hetty@gmail.com"},
			// {code:"3123125", label:"RINTO HASARAP", email:"rinto@gmail.com"},
			// {code:"3123126", label:"BETTY BANDEL", email:"beba@gmail.com"}
			// this.empList = [];
			// {code:"123", label:"ANCOL 1"},{code:"124", label:"ARISTOCRAT CIPTA MANDIRI"},{code:"126", label:"PT AGUNG JAYA"},{code:"127", label:"ANCOL 2"}
			this.supList = [];

			//GET LIST COMPANY DATA
			umService.getAllTenant().then((tenantResult) => {
				//
				this.companyList = tenantResult.data.data;
			});
			authservice.whoAmi().then((value) => {
				this.userInfo = value.data;
				this.tokenID = value.tokenId;
				this.leveltenant = this.userInfo.leveltenant;
				this.groupForm = this.payload;

				if (
					value.data.data.leveltenant === 1 &&
					value.data.data.id !== 2
				) {
					this.adminStatus = true;
				}
			});
		});
	},
};
</script>
